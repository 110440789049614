.wrapper {
  max-width: 750px;
  margin: 0 auto;
  padding: 4em 2em;
  text-align: center;

  h2, h3 {
    margin-top: 2em;
  }

  p {
    font-size: 16px;
  }
}

.header {
  background:
  linear-gradient(
      rgba(0,0,0, 0.4), 
      rgba(0,0,0, 0.55)),
  url('./../../assets/images/header.jpg');
  text-align: center;
  padding-top: 70px;

  h1, p {
    color: white;
    margin-bottom: 0.25em;
  }
}

.firstLine {
  font-weight: 600;
  font-size: 20px !important;
}

.job {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 16px;
}

.jobHeader {
  font-weight: 600;
  font-size: 16px;
}

.finish {
  font-size: 36px;
  color: var(--primary-color);
  font-weight: 600;
}

@media only screen and (max-width : 576px) {
  .header {
    & h1 {
      font-size: 40px;
      margin-top: 1em;
    }
  }
}