.background {
  max-width: 1100px;
  margin: 0 auto;
}

.wrapper {

}

.header {
  margin: 0 0 3em 0;
  text-align: left;
  h2 {
    font-size: 32px;
    margin-bottom: -.75em;
  }
  p {
    font-size: 18px;
    color: var(--primary-color);
  }
}

.form {
  max-width: 600px;
  margin: 0 auto;

  input, textarea {
    font-size: 16px;
    box-shadow: 0 5px 40px 0 rgba(0,0,0,.1);
  }
}

.col {
  margin: .5em 0;
}

.alert {
  margin: -1em auto 2em;
}

.textarea {
  width: 100%;
  margin: 2em 0;
}

.consent {
  p {
    text-align: left;
    padding: 0 3em;
    margin: -1.9em 0 1.5em 0;
    font-size: 12px;
  }
}

.button {
  margin: 1em 0 0 0;

  &_inner {
    font-size: 16px;
    padding: 0 2em;
  }
}

.success {
  text-align: center;
  & i {
    font-size: 44px;
    color: var(--primary-color);
  }
}

@media only screen and (max-width: 900px) {
  .header, .button {
    text-align: left;
    margin: 0 0 2em 0;
  }
  .form {
    input {
      margin: 0.5em 0;
    }
  }
  .button {
    &_inner {
      width: 100%
    }
  }
}