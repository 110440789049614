html {
  font-size: 62.5%;
}

:root {
  --primary-color: #FF9002;
  --background-color: #ffffff;
  --background-color-dark: #000;
}

body {
  font-family: 'Open Sans', sans-serif;
  background: white;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Great Vibes', cursive;
}

h1 {
  font-size: 50px;
  font-weight: 500;
}

h2 {
  font-size: 40px;
  font-weight: 600;
}

h3 {
  font-size: 40px;
}

// Primary Button

.button_primary {
  font-size: 16px;
  padding: 0 2em;
}

.button_primary_round {
  font-size: 16px;
  border-radius: 99px;
}

// Secondary Button

.button_secondary {
  font-size: 16px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.button_secondary_round {
  font-size: 16px;
  border-radius: 99px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}