.background {
  padding: 0 0 3em 0;
}

.wrapper {
  max-width: 1100px;
  padding: 4rem;
  margin: 0 auto;
  justify-content: space-evenly;
  min-height: 650px;
  align-items: center;

  & h3 {
    font-size: 36px;
    margin-top: 1em;
    color: var(--primary-color);
  }

  & p {
    margin: 1em 0 .33em;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.info {
  margin-top: 6em;
  font-size: 12px;
}
