.background {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}

.wrapper {
  max-width: 1100px;
  min-height: 600px;
  padding: 4rem;
  margin: 0 auto;
  justify-content: space-evenly;
}

.content {
  text-align: left;
  & h1 {
    font-size: 32px;
  }
  & p {
    font-size: 16px;
  }
}

.button {
  margin-top: 1em;
  padding: 0 2em;
}

.card {
  max-width: 400px;
  margin: 1em 0 0 auto;
}

.preise {
  background: white;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & p {
    margin: 0;
    font-weight: 600;
    color: var(--primary-color); 
  }
}

.image {
  padding: 2rem 0;
}

@media only screen and (max-width : 600px) {
  .wrapper {
    padding: 5rem 2rem 3rem;
  }
  .content {
    text-align: center;
    padding: 3rem 1rem 2rem;
  }
}

@media only screen and (min-width : 600px) and (max-width : 900px) {
  .wrapper {
    padding: 5rem 2rem;
  }
  .content {
    text-align: center;
    margin-top: 2rem;
  }
  .card {
    margin: 1em auto;
  }
  .image {
    padding: 6rem 0 2rem;
  }
}