.divider_right {
  height: 2px;
  background: var(--primary-color);
  width: 50%;
  margin-left: auto;
}

.divider_left {
  height: 2px;
  background: var(--primary-color);
  width: 50%;
}