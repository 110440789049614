.cookies {
  background: var(--background-color-dark);
  position: fixed; 
  z-index: 1000;
  bottom: 25px;
  right: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 3rem;
  border-radius: 5px;
  max-width: 575px;
  font-size: 14px;

  & p {
    color: rgba(255, 255, 255, 0.75);
    margin: 0.5rem 0 2rem;
  }

  &_header {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 1rem 0;

    & img {
      height: 30px;
    }

    & p {
      font-size: 18px;
      font-weight: 600;
      color: white;
      margin: 0.2rem 0 0;
    }
  }

  &_button {
    border-radius: 99px;
    padding: 0 3.33rem;
    margin-right: 2rem;
    font-size: 16px;
  }

  &_decline {
    color: white;
    font-size: 13px;
  }
}

.consent {
  margin: 0.5em 0 2.5em 0.1em;

  &_col {
    margin: 0 0.33em 0 0;
  }

  span {
    color: rgba(255, 255, 255, 0.75);
    font-size: 14px;
  }

  & i {
    color: red;
    margin: 0 0.25em;
  }
}


@media only screen and (max-width: 600px) {
  .cookies {
    margin-left: 10px;
    bottom: 10px;
    right: 10px;

    &_button {
      margin: 0 0 2rem 0
    }
  }
  .links {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .consent {
    margin: 0 0 1em 0.1em;
  
    &_col {
      margin: 0 0 1em 0;
    }
  }
} 