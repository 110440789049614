.background {
  background-image: 
  linear-gradient(
      rgba(0, 0, 0, 0.4), 
      rgba(0, 0, 0, 0.55)),
  url('./../../../../assets/images/gasthof_hero.JPG');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 70%;
  margin: 0 auto;
  box-shadow: 0 2px 10px 0 black;
  padding-top: 70px;
}

.wrapper {
  max-width: 1100px;
  padding: 4rem;
  margin: 0 auto;
  justify-content: space-evenly;
  min-height: 650px;
  align-items: center;
}

.content {
  text-align: center;
}


.button {
  margin: 1em 0 0 0;
  padding: 0 2em;
  border-radius: 2px;
}

.headline {
  color: white;
}

.text {
  color: white;
  font-size: 16px;
  max-width: 650px;
  margin: 1.5em auto 2em;
}

@media only screen and (max-width : 576px) {
  .wrapper {
    padding: 4rem 2rem;
  }
  .content {
    text-align: center;
    padding: 0 1rem;
  }
}

@media only screen and (min-width : 576px) and (max-width : 992px) {
  .wrapper {
    padding: 5rem 2rem;
  }
  .content {
    text-align: center;
  }
}