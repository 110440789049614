.background {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}

.wrapper {
  max-width: 1100px;
  min-height: 600px;
  padding: 4rem;
  margin: 0 auto;
  justify-content: space-evenly;
}

.video_wrapper {
  position: relative;
  box-shadow: 2px 2px 10px 10px rgba(0, 0, 0, 0.1);
}

.video {
  margin-bottom: -5px;
}

.button {
  position: absolute;
  height: 200px;
  cursor: pointer;
  border-radius: 16px;
  padding: 24px;
  background-color: white;
}

@media only screen and (min-width: 900px) {
  .video_wrapper {
    width: 800px;
    height: 450px;
  }
}

@media only screen and (max-width: 600px) {
  .wrapper {
    flex-direction: column-reverse;
    padding: 5rem 2rem 3rem;
    min-height: 400px;
  }
  .video_wrapper {
    width: 90%;
  }
  .button {
    display: none;
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .wrapper {
    flex-direction: column-reverse;
    min-height: 500px;
    padding: 5rem 2rem;
  }
  .video_wrapper {
    width: 80%;
    height: auto;
  }
  .button {
    display: none;
  }
}
