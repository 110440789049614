.background {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}

.wrapper {
  max-width: 1100px;
  min-height: 600px;
  padding: 4rem;
  margin: 0 auto;
  justify-content: space-evenly;
}

.content {
  text-align: left;
  & p {
    font-size: 16px;
  }
}

.button {
  margin: 1em 0 0 0;
  padding: 0 2em;
}

.image {
  text-align: center;
  & img {
    width: 110%
  }
}

@media only screen and (max-width : 600px) {
  .wrapper {
    flex-direction: column-reverse;
    padding: 5rem 2rem 3rem;
  }
  .content {
    text-align: center;
    padding: 3rem 1rem 2rem;
  }
  .image {
    & img {
      width: 100%
    }
  }
}

@media only screen and (min-width : 600px) and (max-width : 900px) {
  .wrapper {
    flex-direction: column-reverse;
    padding: 5rem 2rem;
  }
  .content {
    text-align: center;
    margin-top: 2rem;
  }
  .image {
    & img {
      width: 100%
    }
  }
}

@media only screen and (max-width : 1100px) {
  .image {
    & img {
      width: 100%;
    }
  }
}