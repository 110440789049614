.hero {
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}

.wrapper {
  max-width: 1100px;
  padding: 4rem;
  margin: 0 auto;
  justify-content: space-evenly;
}

.content {
  text-align: left;
}

.image {
  text-align: center;
  & img {
    width: 80%
  }
}

@media only screen and (max-width : 576px) {
  .wrapper {
    flex-direction: column-reverse;
    padding: 5rem 2rem 3rem;
  }
  .content {
    text-align: center;
    padding: 3rem 1rem 2rem;
  }
}

@media only screen and (min-width : 576px) and (max-width : 992px) {
  .wrapper {
    flex-direction: column-reverse;
    padding: 5rem 2rem;
  }
  .content {
    text-align: center;
    margin-top: 2rem;
  }
}