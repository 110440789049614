.hero {
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}

.wrapper {
  max-width: 1100px;
  padding: 6rem 4rem 6rem;
  margin: 0 auto;
  justify-content: space-evenly;
  align-items: baseline;
}

.content {
  text-align: left;
}


@media only screen and (max-width : 600px) {
  .wrapper {
    padding: 5rem 2rem 3rem;
  }
  .content {
    padding: 0 1rem 4rem;
    h1 {
      font-size: 40px;
    }
  }
}

@media only screen and (min-width : 600px) and (max-width : 1023px) {
  .wrapper {
    padding: 5rem 2rem;
  }
  .content {
    padding: 6rem 1rem;
  }
}