.focus {
  font-weight: 600;
  color: var(--primary-color);
}

.popup {
  font-size: 14px;
}

.times {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 0.33em 0;
  color: var(--primary-color);
}

.time {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 0.33em 0;
}
