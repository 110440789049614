.divider_right {
  height: 2px;
  background: var(--primary-color);
  width: 50%;
  margin-left: auto;
}

.divider_left {
  height: 2px;
  background: var(--primary-color);
  width: 50%;
}

.location {
    text-align: center;
    padding: 1.5em 0 1em;
}