.background {
  background-image: 
  linear-gradient(
      rgba(0, 0, 0, 0.66), 
      rgba(0, 0, 0, 0.75)),
  url('./../../assets/images/header.jpg');
}

.footer {
  max-width: 1100px;
  margin: 0 auto;
  padding: 4em 2em;
  font-size: 14px;

  ul {
    list-style: none;
    padding-inline-start: 0px;
  }
  li {
    margin: 0.4em 0;
    font-size: 14px;
    color: rgba(255,255,255,0.75);
  }
  p {
    color: white;
    font-size: 16px;
    font-weight: 600;
  }
  i {
    margin: 0 0.33em 0 0;
    color: white;
  }
  a {
    text-decoration: none;
    color: rgba(255,255,255,0.75)
  }
  a:hover {
    color: var(--primary-color);
  }
}

.logo {
  height: 75px;
  width: 140px;
  margin: 0 0 1em 0;
  border-radius: 2px;
}

.creator {
  text-align: center;
  color: rgba(255, 255, 255, 0.75);
  padding: 1em 0 1em;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 13px;
}

@media only screen and (max-width : 600px) {
  .footer {
    flex-direction: column;
    & ul {
      margin-bottom: 3em;
    }
  }
}

@media only screen and (min-width : 600px) and (max-width : 900px) {
  .footer {
    & ul {
      margin-bottom: 3em;
    }
  }
}

