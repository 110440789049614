.wrapper {
  max-width: 1100px;
  margin: 0 auto;
  padding: 4em 2em;

  & img {
    width: 33%;
    padding: 0.5em;
  }

  & ul {
    padding-inline-start: 0;
    overflow: hidden;
  }
}

.masonry {
  min-height: 600px;
  margin: 0 auto;
}

.header {
  background:
  linear-gradient(
      rgba(0,0,0, 0.4), 
      rgba(0,0,0, 0.55)),
  url('./../../assets/images/header.jpg');
  text-align: center;
  padding-top: 70px;

  h1, p {
    color: white;
    margin-bottom: 0.25em;
  }
}


@media only screen and (max-width : 600px) {
  .header {
    & h1 {
      font-size: 40px;
      margin-top: 1em;
    }
  }
  .wrapper {
    & img {
      width: 100%;
    }
  }
}