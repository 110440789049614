.background {
  background: rgba(0,0,0,.4);
  background-size: cover;
  box-shadow: 0 1px 10px 2px rgba(255, 144, 2, 0.25);
  border-bottom: 1px solid var(--primary-color);
  margin-bottom: -70px;
  z-index: 1;
  position: relative;
}

.wrapper {
  max-width: 1100px;
  padding: 0 2em;
  margin: 0 auto;
  height: 70px;
}

.logo {
  height: 133px;
  width: 150px;
  border-radius: 0 0 4px 4px;
  background: white;
  position: absolute;
  z-index: 1;
  top: -38px;
  box-shadow: 0 1px 10px 2px rgba(255, 144, 2, 0.25);
  & img {
    width: 135px;
    margin: 28px 0 0 5px;
  }
  &_mobile {
    padding: 0 0 0 .5em;
  }
}

.links {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  font-weight: 600;
  & a {
    color: white;
    padding: 1.5em;
    &:hover {
      color: var(--primary-color)
    }
    &:focus {
      text-decoration: none;
    }
  }
  &_mobile {
    color: black !important;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    padding: 0 0 0 0;
    text-align: center;
    & a {
      padding: 1em 0;
    }
  }
}

.button {
  text-align: right;
  color: var(--primary-color);
  & i {
    font-size: 16px;
    margin-right: .5em;
    color: var(--primary-color);
  }
}

.Hamburger {
  display: flex;
  justify-content: flex-end;
  color: white;
}

.menu_bars {
  height: 25px;
  float: right;
}

.drawer_content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

@media only screen and (max-width : 600px) {
  .wrapper {
    padding: 0 2rem;
  }
  .links {
    font-size: 16px;
  }
  .links {
    flex-direction: column;
  }
  .button {
    display: none;
  }
}

@media only screen and (min-width : 600px) and (max-width : 1023px) {
  .wrapper {
    padding: 0 2rem;
  }
  .links {
    font-size: 16px;
  }
  .links {
    flex-direction: column;
  }
  .button {
    display: none;
  }
}