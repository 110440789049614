.background {
  background-image: 
  linear-gradient(
      rgba(0, 0, 0, 0.5), 
      rgba(0, 0, 0, 0.6)),
  url('./../../../../assets/images/home_hero.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
  box-shadow: 0 2px 10px 0 black;
  padding-top: 70px;
}

.wrapper {
  max-width: 1100px;
  padding: 4rem;
  margin: 0 auto;
  justify-content: space-evenly;
  min-height: 650px;
  align-items: center;
}

.content {
  text-align: center;
}

.button {
  margin: 1em 2em 0 0;
  padding: 0 2em;
  border-radius: 2px;
}

.button_two {
  padding: 0 2em;
  border: 2px solid var(--primary-color);
  background: transparent;
  color: white;
  &:hover {
    background: transparent;
    color: var(--primary-color);
    border-color: var(--primary-color)
  }
}

.headline {
  color: var(--primary-color);
}

.text {
  color: white;
  font-size: 20px;
  max-width: 650px;
  margin: 1.5em auto 2em;
}

.times {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 0.33em 0;
  color: var(--primary-color);
}

.time {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 0.33em 0;
}

@media only screen and (max-width : 576px) {
  .wrapper {
    padding: 4rem 2rem;
  }
  .content {
    text-align: center;
    padding: 0 1rem;
  }
  .button {
    margin: 1em 0 2em;
  }
  .times,
  .time {
    flex-direction: column
  }
  .text {
    font-size: 16px;
  }
}

@media only screen and (min-width : 576px) and (max-width : 992px) {
  .wrapper {
    padding: 5rem 2rem;
  }
  .content {
    text-align: center;
  }
  .times,
  .time {
    flex-direction: column
  }
}